<template>
	<div>
		<div class="print-hide">
			<FilterSelector
				@searchData="getVotedSongs()"
				:disablePrint="this.tableData.length === 0"
				:filterForReport="true"
				:buttonsToDisplay="[
					buttonEnum.YESTERDAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
				@extraFunction="printReport()"
				:extraFunction="true"
				:extraFunctionLabel="'IMPRIMIR'"
				:disableExtraFunction="this.tableData.length === 0"
			>
			</FilterSelector>
		</div>
		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide"
		>
			<Spinner />
		</div>
		<div v-show="!loading" class="print-hide">
			<div
				v-if="this.tableData.length > 0"
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<div class="card-icon">
							<i class="material-icons">thumbs_up_down</i>
						</div>
						<h4>Votação</h4>
					</md-card-header>

					<md-card-content>
						<div class="md-layout">
							<div
								class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
							>
								<md-table
									v-model="tableData"
									table-header-color="blue"
									:md-sort.sync="currentSort"
									:md-sort-order.sync="currentSortOrder"
								>
									<md-table-row slot="md-table-row" slot-scope="{ item }">
										<md-table-cell md-label="Título">{{
											item.title
										}}</md-table-cell>
										<md-table-cell md-label="Artista">{{
											item.artist
										}}</md-table-cell>
										<md-table-cell
											md-label="Curti"
											md-sort-by="percentual_likes"
											>{{ item.total_likes | formatNumber }} ({{
												item.percentual_likes
											}}%)</md-table-cell
										>
										<md-table-cell
											md-label="Não Curti"
											md-sort-by="percentual_dislikes"
											>{{ item.total_dislikes | formatNumber }} ({{
												item.percentual_dislikes
											}}%)</md-table-cell
										>
										<md-table-cell md-label="Total" md-sort-by="total">{{
											item.total | formatNumber
										}}</md-table-cell>
										<md-table-cell>
											<el-button
												round
												type="danger"
												icon="el-icon-delete"
												@click="deleteMusic(item)"
											></el-button>
										</md-table-cell>
									</md-table-row>
								</md-table>
							</div>
						</div>
					</md-card-content>
				</md-card>
			</div>
			<div v-if="this.tableData.length > 0">
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<div class="card-icon">
							<i class="material-icons">clear_all</i>
						</div>
						<h4>Total por plataforma</h4>
					</md-card-header>

					<md-card-content>
						<div class="md-layout">
							<div
								class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
							>
								<md-table v-model="tableData2" table-header-color="blue">
									<md-table-row slot="md-table-row" slot-scope="{ item }">
										<md-table-cell md-label="Android"
											>{{ item.Android | formatNumber }}
											{{
												Math.round(
													(item.Android * 100) /
														(item.Android +
															item.IOS +
															item.Windows_Phone +
															item.Web)
												)
											}}%</md-table-cell
										>
										<md-table-cell md-label="IOS"
											>{{ item.IOS | formatNumber }} ({{
												Math.round(
													(item.IOS * 100) /
														(item.Android +
															item.IOS +
															item.Windows_Phone +
															item.Web)
												)
											}}%)</md-table-cell
										>
										<md-table-cell md-label="Windows Phone"
											>{{ item.Windows_Phone | formatNumber }} ({{
												Math.round(
													(item.Windows_Phone * 100) /
														(item.Android +
															item.IOS +
															item.Windows_Phone +
															item.Web)
												)
											}}%)</md-table-cell
										>
										<md-table-cell md-label="Web"
											>{{ item.Web | formatNumber }} ({{
												Math.round(
													(item.Web * 100) /
														(item.Android +
															item.IOS +
															item.Windows_Phone +
															item.Web)
												)
											}}%)</md-table-cell
										>
									</md-table-row>
								</md-table>
							</div>
						</div>
					</md-card-content>
				</md-card>
			</div>
		</div>
		<div
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide"
			style="padding: 0"
		>
			<md-card style="text-align: center" v-show="msgError">
				<md-card-content>
					<label for="">Sem informações pra serem exibidas.</label>
				</md-card-content>
			</md-card>
		</div>
		<div class="md-layout" id="impressao">
			<div>
				<img src="../../../../public/img/logo-mobradio-black.png" alt="" />
				<h1>Relatório de Votação Musical</h1>
			</div>
			<div class="titleRight">
				<b class="nameRadio">{{
					nameRadioReport ? nameRadioReport : "MobRadio"
				}}</b>
				&nbsp;
				<img class="logoRadio" :src="logo" alt="" />
			</div>
			<div>
				<span>Período: {{ periodStart }} até: {{ periodEnd }} </span>
			</div>
			<div v-if="this.tableData.length > 0" class="contentBody impressao">
				<div class="divTable">
					<h4>Votação</h4>
					<span style="padding-left: 5px; font-style: italic"
						>Ordenado por {{ orderBy }}</span
					>
					<div
						class="md-content md-table md-theme-default"
						table-header-color="blue"
					>
						<div
							class="md-content md-table-content md-scrollbar md-theme-default"
						>
							<table>
								<thead>
									<tr>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Título</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Artista</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Curti</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Não Curti</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Total</div>
											</div>
										</th>
									</tr>
								</thead>
								<tfoot class="report-footer">
									<tr>
										<td colspan="6">
											Fonte de dados: MobRadio Analytics &nbsp;
											<img
												class="footerIcon"
												src="../../../../public/img/favicon.png"
											/>
										</td>
									</tr>
								</tfoot>
								<tbody>
									<tr
										class="md-table-row"
										v-for="(item, index) in tableData"
										:key="index"
									>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.title }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.artist }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total_likes | formatNumber }} ({{
													item.percentual_likes
												}}%)
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total_dislikes | formatNumber }} ({{
													item.percentual_dislikes
												}}%)
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total | formatNumber }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="divTable">
					<h4>Total por plataforma</h4>
					<div
						class="md-content md-table md-theme-default"
						table-header-color="blue"
					>
						<div
							class="md-content md-table-content md-scrollbar md-theme-default"
						>
							<table>
								<thead>
									<tr>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Android</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">iOS</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Windows Phone</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Web</div>
											</div>
										</th>
									</tr>
								</thead>
								<tfoot class="report-footer">
									<tr>
										<td colspan="6">
											Fonte de dados: MobRadio Analytics &nbsp;
											<img
												class="footerIcon"
												src="../../../../public/img/favicon.png"
											/>
										</td>
									</tr>
								</tfoot>
								<tbody>
									<tr
										class="md-table-row"
										v-for="(item, index) in tableData2"
										:key="index"
									>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.Android | formatNumber }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.IOS | formatNumber }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.Windows_Phone | formatNumber }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.Web | formatNumber }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import filters from "../Components/FilterSelector/filters";
import buttonEnum from "../Components/FilterSelector/buttonEnum";
import Spinner from "../../../components/Icons/Spinner";
import Rodape from "./Rodape.vue";
import models from "../../../routes/models.js";
import selectRadio from "../../../utils/events/select-radio.js";
import { ChartCard } from "@/components";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { DatePicker, Button } from "element-ui";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";
const numeral = require("numeral");
const locale = require("numeral/locales");
numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	components: {
		ChartCard,
		FilterSelector,
		[DatePicker.name]: DatePicker,
		[Button.name]: Button,
		Rodape,
		Spinner,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			loading: false,
			tableData: [],
			tableData2: [],
			selectedMusics: [],
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			time1: null,
			time2: null,
			widthPicker: 100 + "%",
			filter: 2,
			logo: "",
			currentSort: "total",
			currentSortOrder: "asc",
			periodStart: null,
			periodEnd: null,
			msgError: false,
			orderBy: "total",
		};
	},
	methods: {
		getVotedSongs() {
			this.tableData = [];
			this.tableData2 = [];
			this.selectedMusics = [];
			this.currentSort = "total";
			this.currentSortOrder = "asc";
			this.loading = true;
			this.msgError = false;
			let dateStart = new Date(filters.startDate);
			let dateEnd = new Date(filters.endDate);
			let aux1 =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate() +
				" 00:00:00";
			let aux2 =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate() +
				" 23:59:59";
			this.periodStart = new Date(dateStart).toLocaleDateString("pt-BR");
			this.periodEnd = new Date(dateEnd).toLocaleDateString("pt-BR");
			models()
				.get(
					`music/getVotedSongsConsolidated?appKey=${this.appKey}&startDate=${aux1}&endDate=${aux2}&filterKey=${this.filter}`
				)
				.then((voted) => {
					if (voted.data.length > 0) {
						for (let item of voted.data) {
							this.tableData.push({
								title: item.title,
								artist: item.artist,
								total_likes: item.total_likes,
								total_dislikes: item.total_dislikes,
								percentual_likes: Math.round(
									(item.total_likes * 100) / item.total
								),
								percentual_dislikes: Math.round(
									(item.total_dislikes * 100) / item.total
								),
								total: item.total,
							});
						}
						this.btnImprimir = false;
						return Promise.resolve(
							models().get(
								`music/getVotedSongsPlataformConsolidated?appKey=${this.appKey}&startDate=${aux1}&endDate=${aux2}`
							)
						).then((consolidated) => {
							if (consolidated.data.length > 0) {
								this.tableData2 = consolidated.data;
							}
							this.msgError = false;
							this.loading = false;
						});
					} else {
						this.msgError = true;
						this.loading = false;
					}
				})
				.catch((e) => {
					Swal(
						"Erro",
						"Não foi possível obter as informações, por favor tente novamente"
					);
					console.log(e);
				});
		},

		deleteMusic(music) {
			const musicTitle = music.title;
			const musicArtist = music.artist;
			let dateStart = new Date(this.time1);
			let dateEnd = new Date(this.time2);
			let aux1 =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate() +
				" 00:00:00";
			let aux2 =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate() +
				" 23:59:59";
			Swal.fire({
				type: "question",
				title: "Tem certeza desta ação?",
				text: `Você está prestes a apagar a música '${musicTitle} - ${musicArtist}' do relatório. 
                            Esta ação é irreversível e pode afetar a confiabilidade do relatório. Tem certeza de que deseja
                            prosseguir com esta ação?`,
				showCancelButton: true,
				cancelButtonText: "CANCELAR",
				confirmButtonText: "CONFIRMAR",
			}).then((result) => {
				if (result.value) {
					models()
						.delete(
							`music/deleteMusicFromReport?appKey=${this.appKey}&startDate=${aux1}&endDate=${aux2}&artist=${musicArtist}&title=${musicTitle}`
						)
						.then(() => {
							Swal.fire({
								type: "success",
								title: "Música deletada",
								text: "A música foi deletada com sucesso do relatório.",
							}).then(() => {
								this.getVotedSongs();
							});
						})
						.catch(() => {
							Swal.fire({
								type: "error",
								title: "Erro ao deletar",
								text: "Houve um erro no sistema ao tentar apagar a música do relatório. Tente novamente ou entre em contato com o suporte caso o erro persista.",
							});
						});
				}
			});
		},

		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return b[sortBy] - a[sortBy];
				}
				return b[sortBy] - a[sortBy];
			});
		},
		printReport() {
			window.print();
		},
	},
	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
			// this.begin()
		}

		selectRadio.$on("selectRadio", (payload) => {
			this.tableData = [];
			this.tableData2 = [];
			this.selectedMusics = [];
			this.btnImprimir = true;
			(this.appKey = payload.key), (this.nameRadioReport = payload.name);
			this.logo = payload.logo;

			this.getVotedSongs();
		});
	},
	watch: {
		time1() {
			if (this.time1 !== null && this.time2 !== null) {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
		},
		time2() {
			if (this.time1 !== null && this.time2 !== null) {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
		},
		currentSort() {
			if (this.currentSort == "total") {
				this.orderBy = "total";
			}
			if (this.currentSort == "percentual_likes") {
				this.orderBy = "curti";
			}
			if (this.currentSort == "percentual_dislikes") {
				this.orderBy = "não curti";
			}
		},
	},
};
</script>
<style>
#impressao {
	display: none;
}

.datepicker-center {
	margin-top: -14px;
}

@page {
	size: auto;
	margin: 10mm 10mm 10mm 10mm;
}

@media print {
	#principal {
		display: none;
	}
	.print-hide {
		display: none;
	}
	#impressao {
		display: block;
		margin-top: -30px;
	}

	tr {
		page-break-inside: avoid;
	}

	table.report-container {
		page-break-after: always !important;
	}

	thead.report-header {
		display: table-header-group !important;
	}

	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}

	body {
		font-size: 8pt;
	}

	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.order-by {
		font-size: 10pt;
		font-style: italic;
		padding-left: 5px;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleRight {
		float: right;
		margin-top: -80px;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}

	.contentBody {
		margin-top: 50px;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 20px;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}
}
</style>
